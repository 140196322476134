import './style.css';
import './responsive.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';
import NavBar from './NavBar';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';
import { Routes, Route } from "react-router-dom";
import Home from './components/Home'
import About from './components/About'
import Services from './components/Services'
import Resources from './components/Resources'
import FAQs from './components/FAQs'
import ErrorPage from './components/ErrorPage'
import Analytics from './Analytics'
import { useNavigate } from 'react-router-dom';

function App() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  return (
    <>
      <header className="App-header">
        <Analytics trackingId="G-ZH71HLTB40" />
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </header>
      <Outlet context={[show, setShow, handleClose, handleShow, navigate]}/>
      <Footer />
    </>
  );
}

export default App;
