import HeraLogo from '../assets/logos/Hera Logo_MainWhite800.png';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Fade } from 'react-swift-reveal';
import { Link } from 'react-router-dom'


const HomeHero = ({ show, setShow, handleClose, handleShow, navigate }) => {

    return (
        <section className="hero-bg position-relative">
            <div className="hero-overlay position-absolute top-0 end-0 bottom-0 start-0"></div>
            <Container className="position-relative">
                <Row>
                    <Col md={4}>
                        <div className="logo">
                            <img src={HeraLogo} alt="Hera Pathways Main Logo in White"></img>
                        </div>
                    </Col>
                    <Col md={8}>
                    <Fade delay={500} timeout={1500}>
                        <div className="prime-cta">
                            <h1>Embrace Mental Wellness on Your Journey</h1>
                            <br />
                            <p className="p-sub-copy pb-4">Our compassionate therapists specialize in supporting you as you navigate the emotional ups and downs of your transformative journey.</p>
                            <Link to="/about"><Button variant="secondary" size='lg' title="get started">Get Started</Button></Link>
                        </div>
                    </Fade>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default HomeHero