import Container from 'react-bootstrap/Container'
import WithXavi from '../assets/images/withXavi.jpg'
import family from '../assets/images/family.152.jpg'
import Mom_Baby from '../assets/images/mom-and-baby.jpg'
import Profile from '../assets/images/CathyProfile.jpg'
import Icon from '../assets/logos/Hera Logo_Icon Full Color copy 4.png'
import Alison from '../assets/images/akojicic.jpg'
import Coffee from '../assets/images/priscilla-du-preez-K8XYGbw4Ahg-unsplash.2000.jpg'
// import bushes_leaves from '../assets/images/bushes-of-leaves-svgrepo-com.png'
import Support from '../assets/images/CathyManny.jpg'
import { Helmet } from 'react-helmet'
// import FullLogo from '../assets/logos/HeraLogo_MainWhite.96.png'
// import MetaPhoto from '../assets/logos/HeraLogo_MainFullColor.png'

function About() {

    return (
        <>
            <Helmet>
                <title>About | Hera Pathways</title>
                <meta name="description" content="Our compassionate therapists specialize in supporting you as you navigate the emotional ups and downs of this transformative journey." />
                <meta property="og:title" content="About Us" />
                <meta property="og:description" content="Our compassionate therapists specialize in supporting you as you navigate the emotional ups and downs of this transformative journey." />
                <meta property="og:image" content="https://www.herapathways.com/static/media/Hera%20Logo_Main%20Full%20Color_sansTitle.4809f62b843a566ba4ed.png" />
                <meta property="og:url" content="https://herapathways.com/about" />
            </Helmet>
            <section>
                <Container className='about-design'>
                    <div className='about-copy'>
                        <h2 className="bold-text">Cathlyn Fraguela Rios<br />LICSW, PMH-C</h2>
                        <h3 className="bold-font">Hera Pathways Founder</h3>
                        <p>
                            <a className="regular-italic-text" style={{ color: "#ffffff" }} href="https://api.portal.therapyappointment.com/n/public/clientRegistration?therapistId=9A4EE40115224FBF8A651F8CB78A4981" title="Book Appointment" target="_blank" rel="noreferrer"><span><img alt="Hera Pathways Logol" src={Icon} style={{ width: "55px", boxShadow: "none" }}></img></span>Book with Cathlyn<span><img alt="Hera Pathways Logol" src={Icon} style={{ width: "55px", boxShadow: "none" }}></img></span></a>
                        </p>
                        <p>
                            <em>Education: </em> Masters of Social Work University of Washington.<br /><br />
                            <em>Practice Philosophy: </em>I believe that making connections with people is the cornerstone of doing meaningful work with them. Helping them think through whatever challenges they are going through. I envision my work to be figuring out what works for each individual so that the change can be sustainable.<br /><br /><em>Experience: </em>My experience includes working in community mental health, Inpatient psychiatry, integrated primary care and private practice. I have training in different evidence based practices such as Motivational Interviewing, Cognitive Behavioral Therapy (CBT), Trauma Focused Cognitive Behavioral Therapy (TF-CBT), Acceptance and Committement Therapy (ACT), Interpersonal Psychotherapy as well as Mindfulness stress reduction. Each offer valuable strategies and techniques that I bring to sessions and offer what might work best for you. I have worked with individuals at different ages and stages in their lives from children in school based therapy to older adults with Alzeihmers and their families. My approach is to truly understand and help forge a path forward supporting you along the way.<br /><br /><em>Additional Languages Spoken: </em>Spanish<br /><br /><em>Licensed and providing services in: </em> AK, CA, ID, MT, NM, OR, TX, and WA
                        </p>
                    </div>
                    <div className="about-img-1">
                        <img src={Profile} alt='Professional headshot of Cathlyn.'></img>
                    </div>
                    <div className="about-img-2">
                        <img src={family} alt="Cathly with her husband and son."></img>
                    </div>
                    <div className="about-img-3">
                        <img src={Mom_Baby} alt="Cathlyn and her new born baby sitting with sunshine coming in through the window."></img>
                    </div>
                    <div className="about-img-4">
                        <img src={WithXavi} alt="Cathlyn and her son"></img>

                    </div>
                    <div className="about-img-7">
                        <img src={Support} alt="Cathlyn and her dog on a walk with mountains and water in the background"></img>
                    </div>
                </Container>
            </section>
            <section>
                <Container className="about-design">
                    <div className='about-copy-alison'>
                        <h2 className="bold-text">Alison Kojicić<br />LICSW</h2>
                        <p>
                            <a className="regular-italic-text" style={{ color: "#ffffff" }} href="https://api.portal.therapyappointment.com/n/public/clientRegistration?therapistId=56457B301D954EDFB840879D4CD5DD1E" title="Book Appointment" target="_blank" rel="noreferrer"><span><img alt="Hera Pathways Logol" src={Icon} style={{ width: "55px", boxShadow: "none" }}></img></span>Book with Alison<span><img alt="Hera Pathways Logol" src={Icon} style={{ width: "55px", boxShadow: "none" }}></img></span></a>
                        </p>
                        <p>
                            <em>Education: </em> Masters of Social Work at University of Michigan<br /><br />
                            <em>Practice Philosophy: </em>I find joy in accompanying individuals on their journeys toward personal growth, healing, and self-discovery. With a focus on issues such as anxiety, depression, stress, and grief, I employ a blend of therapeutic modalities that address both physical and emotional symptoms. My approach integrates solution-focused collaboration, mindfulness practices, cognitive behavioral therapy, and physical tools, tailored to each individual's unique needs, experiences, and strengths. I believe the therapeutic relationship is a cornerstone of effective treatment and infuse my work with humor, compassion, and authenticity to foster an environment where clients can be their true selves.
                        </p>
                    </div>
                    <div className='about-copy-alison-mobile'>
                        <h2 className="bold-text">Alison Kojicić<br />LICSW</h2>
                        <p>
                            <a className="regular-italic-text" style={{ color: "#ffffff" }} href="https://api.portal.therapyappointment.com/n/public/clientRegistration?therapistId=56457B301D954EDFB840879D4CD5DD1E" title="Book Appointment" target="_blank" rel="noreferrer"><span><img alt="Hera Pathways Logol" src={Icon} style={{ width: "55px", boxShadow: "none" }}></img></span>Book with Alison<span><img alt="Hera Pathways Logol" src={Icon} style={{ width: "55px", boxShadow: "none" }}></img></span></a>
                        </p>
                        <p>
                            <em>Education: </em> Masters of Social Work at University of Michigan<br /><br />
                            <em>Practice Philosophy: </em>I find joy in accompanying individuals on their journeys toward personal growth, healing, and self-discovery. With a focus on issues such as anxiety, depression, stress, and grief, I employ a blend of therapeutic modalities that address both physical and emotional symptoms. My approach integrates solution-focused collaboration, mindfulness practices, cognitive behavioral therapy, and physical tools, tailored to each individual's unique needs, experiences, and strengths. I believe the therapeutic relationship is a cornerstone of effective treatment and infuse my work with humor, compassion, and authenticity to foster an environment where clients can be their true selves.<br /><br /><em>Experience: </em>With nearly 20 years of clinical social work behind me, I draw upon a wide variety of experiences with diverse populations. My career has encompassed working with youth, perinatal parents, and refugee/immigrant communities, particularly those from Slavic countries. From in-home family therapy with gang-affiliated teens to a suburban birth clinic, and from Bosnian resettlement in Chicago to assisting Ukrainian asylum seekers in Seattle, I have gained both generalizable skills applicable to everyone and deep insights specific to these communities. While these categories do not limit my current clientele (most of whom do not fit into these groups), these experiences enrich my practice and provide a deeper understanding when relevant.<br /><br /><em>Additional Languages Spoken: </em>I speak some BCS, less Czech, and terrible Hungarian<br /><br /><em>Licensed and providing services in: </em> WA
                        </p>
                    </div>
                    <div className='alison-profile-image'>
                        <img src={Alison} alt="Alison professional profile headshot"></img>
                    </div>
                    <div className='coffee-chat-image'>
                        <img src={Coffee} alt="People having a conversation over a cup of a coffee"></img>
                    </div>
                    <div className='exp-copy-alison'>
                        <p>
                            <em>Experience: </em>With nearly 20 years of clinical social work behind me, I draw upon a wide variety of experiences with diverse populations. My career has encompassed working with youth, perinatal parents, and refugee/immigrant communities, particularly those from Slavic countries. From in-home family therapy with gang-affiliated teens to a suburban birth clinic, and from Bosnian resettlement in Chicago to assisting Ukrainian asylum seekers in Seattle, I have gained both generalizable skills applicable to everyone and deep insights specific to these communities. While these categories do not limit my current clientele (most of whom do not fit into these groups), these experiences enrich my practice and provide a deeper understanding when relevant.<br /><br /><em>Additional Languages Spoken: </em>I speak some BCS, less Czech, and terrible Hungarian<br /><br /><em>Licensed and providing services in: </em> WA
                        </p>
                    </div>
                </Container>
            </section>
            <div className="bush_leaves"></div>
        </>
    );
}

export default About;