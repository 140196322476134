import Container from 'react-bootstrap/Container'
import GeneralServicesImg from '../assets/images/NeedingABreak.jpg'
import IndividualSession from '../assets/images/IndividualSession.jpg'
import perinatalServices from '../assets/images/perinatalServices.jpg'
import Icon from '../assets/logos/Hera Logo_Icon Full Color copy 4.png'
import { Fade, Slide } from 'react-swift-reveal';
import { Helmet } from 'react-helmet';
import FullLogo from '../assets/logos/HeraLogo_MainWhite.96.png'
import PsiLogo from '../assets/logos/0_PSI PMH-C Seal Only-01.png'
import { Link } from 'react-router-dom'

function Services() {
    return (
        <div>
            <Helmet>
                <title>Services | Hera Pathways</title>
                <meta name="description" content="Whether you're grappling with work stress, personal issues, or the complexities of pregnancy, our individual and perinatal therapy services are here to support you." />
                <meta property="og:title" content="Services" />
                <meta property="og:description" content="Whether you're grappling with work stress, personal issues, or the complexities of pregnancy, our individual and perinatal therapy services are here to support you." />
                <meta property="og:image" content={FullLogo} />
                <meta property="og:url" content="/services" />
            </Helmet>
            <Container fluid className="services pt-5">
                <Fade delay={1000} timeout={1500}>
                    <div className="services-img-1">
                        <img style={{ maxWidth: '100%' }} alt="" src={GeneralServicesImg}></img>
                    </div>
                </Fade>
                <Slide
                    left
                    big
                    delay={500} timeout={1000} duration={1000}>
                    <div className="services-intro">
                        At Hera Pathways, we understand that life's challenges can sometimes feel overwhelming. Whether you're grappling with work stress, personal issues, or the complexities of pregnancy, our individual and perinatal therapy services are here to support you.
                    </div>
                </Slide>
            </Container>
            <Container fluid className="services" style={{ padding: '5% 0' }}>
                <div className="telehealth telehealth-bg">
                    <div className="telehealth-img"></div>
                    <Fade delay={1000} timeout={1500}>
                        <div className="serv-title bold-text">
                            Our Services are Simple, Accessible, Effective.
                        </div>
                    </Fade>
                    <Slide
                        right
                        big
                        delay={500} timeout={1000} duration={1000}>
                        <div className="serv-copy">
                            Starting is easy. You can schedule an appointment online at a time that suits you.
                        </div>
                    </Slide>
                    <Slide
                        right
                        big
                        delay={500} timeout={1000} duration={1000}>
                        <div className="serv-copy">
                            Our telehealth platform ensures privacy and convenience, allowing you to access therapy from the comfort of your home.
                        </div>
                    </Slide>
                    <Slide
                        right
                        big
                        delay={500} timeout={1000} duration={1000}>
                        <div className="serv-copy">
                            Telehealth has been proven to be as effective as in-person therapy, providing flexibility and comfort without compromising on the quality of care.
                        </div>
                    </Slide>
                </div>
            </Container>
            <Container fluid className='services pb-5'>
                <Fade delay={1000} timeout={1500}>
                    <div className="services-ind-img">
                        <img alt="" src={IndividualSession}></img>
                    </div>
                </Fade>
                <Slide
                    left
                    big
                    delay={500} timeout={1000} duration={1000}>
                    <div className="services-ind">
                        <h2 className="serv-title bold-text">Individual Therapy Services</h2>
                        <p className="serv-copy">
                            Individual therapy is a personal journey towards healing and self-discovery, conducted one-on-one with a qualified therapist.

                            Through secure and confidential video sessions, our therapists will work with you to understand your unique experiences and challenges. We'll explore coping strategies, discuss emotional responses, and develop personalized plans for your mental well-being.

                            This service is designed to empower you. It's a safe space where you're heard and understood. By engaging in individual therapy, you gain insights into your emotions, learn coping mechanisms, and start a transformative journey towards a healthier, more fulfilling life.<br /><Link to="/about" className="regular-italic-text" style={{ color: "#ffffff" }} title="Book Appointment"><span><img alt="Hera Pathways Logol" src={Icon} style={{ width: "55px", boxShadow: "none" }}></img></span>Start Here<span><img alt="Hera Pathways Logol" src={Icon} style={{ width: "55px", boxShadow: "none" }}></img></span></Link>
                        </p>
                    </div>
                </Slide>
                <Fade delay={1000} timeout={1500}>
                    <div className="services-perin-img">
                        <img alt="" src={perinatalServices}></img>
                    </div>
                </Fade>
                <Slide
                    right
                    big
                    delay={500} timeout={1000} duration={1000}>
                    <div className="services-perinatal">
                        <h2 className="serv-title bold-text"><span><img className='services-psi-logo' src={PsiLogo} alt='Postpartum Support International Logo'></img></span>Certified Perinatal Therapy Services</h2>
                        <div className="serv-copy">
                            Perinatal therapy focuses on the mental well-being of individuals during pregnancy and postpartum. It addresses the emotional and psychological changes and challenges during this significant life transition.

                            In our video sessions, we offer a compassionate and understanding approach to managing the stresses and anxieties that can accompany pregnancy. From dealing with hormonal changes to preparing for parenthood, our therapists provide guidance and support every step of the way.

                            This service offers not just a listening ear, but also practical advice and emotional support tailored to your perinatal and postnatal journey. It’s about nurturing your mental health as you prepare for one of life's most beautiful yet challenging experiences.<br />

                            <br /><Link to="/about" className="regular-italic-text" style={{ color: "#ffffff" }}  title="Book Appointment"><span><img alt="Hera Pathways Logol" src={Icon} style={{ width: "55px", boxShadow: "none" }}></img></span>Let's get started<span><img alt="Hera Pathways Logo" src={Icon} style={{ width: "55px", boxShadow: "none" }}></img></span></Link>
                            
                        </div>
                    </div>
                </Slide>
            </Container>
        </div>
    );
}

export default Services;
