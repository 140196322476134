import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FooterLogo from './assets/logos/HeraLogo_AltWhite.600.png'
import Button from 'react-bootstrap/Button';
import ScrollToTop from './components/ScrollToTop';
import PsiLogo from './assets/logos/0_PSI PMH-C Seal Only-01.png'
import { Link } from 'react-router-dom';


const Footer = () => {
    return (
        <>
            <div>
                <ScrollToTop />
                <Container fluid className="pre-footer pt-5" style={{ padding: '0' }}>
                    <Container>
                        <div className="pre-footer-copy text-center">
                            <h3 className="pre-footer-title">BEGIN YOUR HEALING PATHWAY</h3>
                            <p style={{ padding: '0 10%' }}>Ready to embark on your journey to healing and well-being? Let's start exploring the pathways to a brighter, healthier you. Together, we'll navigate the challenges, find solutions, and build a foundation for a more fulfilling life. Connect with us today.</p><br />
                            <Link to="/about"><Button variant="secondary" size='lg' title="Book Appointment">BOOK APPOINTMENT</Button></Link>
                        </div>
                    </Container>
                    <Container fluid className="footer pt-5">
                        <Row>
                            <Col xs={12} className="card-roles">
                                <ul style={{ listStyleType: 'none' }}>
                                    <li>
                                        <Nav.Link
                                            href="/"
                                            className="bold-text"
                                        >HOME
                                        </Nav.Link>
                                    </li>
                                    <li>
                                        <Nav.Link
                                            href="/about"
                                            className="bold-text"
                                        >ABOUT
                                        </Nav.Link>
                                    </li>
                                    <li>
                                        <Nav.Link
                                            href="/faqs"
                                            className="bold-text"
                                        >FAQS
                                        </Nav.Link>
                                    </li>
                                    <li>
                                        <Nav.Link
                                            href="/services"
                                            className="bold-text"
                                        >SERVICES
                                        </Nav.Link>
                                    </li>
                                    <li>
                                        <Nav.Link
                                            href="/resources"
                                            className="bold-text"
                                        >RESOURCES
                                        </Nav.Link>
                                    </li>
                                </ul>
                            </Col>
                            <Col xs={12} className="card-roles">
                                <div>
                                    <img src={FooterLogo} alt='Hera Logo'></img>
                                </div>
                            </Col>
                            <Col xs={12} className="card-roles pb-5">
                                <div className='psi-logo'>
                                    <a href='https://www.postpartum.net/' title='Postpartum Support International website' target="_blank" rel="noreferrer">
                                        <img src={PsiLogo} alt='Postpartum Support International Logo'></img>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
        </>
    )
}

export default Footer