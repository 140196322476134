import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import HomeCards from './HomeCards';
import HomeHero from './HomeHero';
import HomeAboutUs from './HomeAboutUs'
import HomeFaq from './HomeFaq'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Profile from '../assets/images/CathyProfile.jpg'
import Icon from '../assets/logos/Hera Logo_Icon Full Color copy 4.png'
import Alison from '../assets/images/akojicic.jpg'
// import MaintenanceScreen from './MaintenanceScreen';
import { Helmet } from 'react-helmet';
import FullLogo from '../assets/logos/HeraLogo_MainWhite.96.png'

function Home() {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div >
                <Helmet>
                    <title>Hera Pathways | Embrace Mental Wellness</title>
                    <meta name="description" content="Your mental health journey begins here. Schedule an appointment with our team today and embark on a path to wellness that honors both your unique challenges and your inherent strength." />
                    <meta property="og:title" content="Hera Pathways | Embrace Mental Wellness" />
                    <meta property="og:description" content="Your mental health journey begins here. Schedule an appointment with our team today and embark on a path to wellness that honors both your unique challenges and your inherent strength." />
                    <meta property="og:image" content={FullLogo} />
                    <meta property="og:url" content="/" />
                </Helmet>
                <HomeHero show={show} setShow={setShow} handleClose={handleClose} handleShow={handleShow} />
                <div className="btf-bg">
                    <div style={{ padding: '12% 0 0' }}>
                        <HomeCards />
                        <HomeAboutUs />
                    </div>

                </div>
                <div className="five-arrow-bg">
                    <HomeFaq />
                </div>
                {/* <MaintenanceScreen /> */}
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>You Have Options</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col md={6} sm={12}>
                                <div>
                                    <img src={Profile} alt='Professional headshot of Cathlyn.'></img>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Take Me There
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default Home;
